<template>
  <AppPage hideBottomBar title="Forgot Password" dark container>
    <div class="auth-container">
      <div class="form-container">
        <v-form ref="form" @submit.prevent="sendOtp">
          <h2>{{ $vuetify.lang.t("$vuetify.forgot_your_password") }}</h2>
          <p class="subtitle-1">
            {{ $vuetify.lang.t("$vuetify.lets_get_you_new_password") }}
          </p>
          <v-text-field
            v-model="email"
            type="email"
            outlined
            class="rounded-lg"
            :rules="[rules.required, rules.validEmail]"
            :placeholder="$vuetify.lang.t('$vuetify.enter_your_email')"
            :label="$vuetify.lang.t('$vuetify.email')"
          ></v-text-field>
        </v-form>
      </div>

      <div class="mb-5">
        <app-button @click="sendOtp" :disabled="!email" :loading="loading">{{
          $vuetify.lang.t("$vuetify.submit")
        }}</app-button>
      </div>
    </div>

    <img src="@/assets/images/three-paws.png" class="three-paws" alt="" />
  </AppPage>
</template>

<script>
import { validateEmail } from "@/helpers";

import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("user");
export default {
  name: "forgot-password",
  data() {
    return {
      email: "",
      rules: {
        required: (value) => !!value || "Field is required.",
        validEmail: (email) =>
          validateEmail(email) || "Please enter valid email",
      },
    };
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions(["FORGOT_PASSWORD"]),
    sendOtp() {
      if (this.$refs.form.validate()) {
        this.FORGOT_PASSWORD({ email: this.email });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 2rem - 56px);

  > div {
    width: 100%;
  }

  .form-container {
    flex: 1;
    display: grid;
    place-items: center;

    form {
      width: 100%;
    }
  }
}
</style>
